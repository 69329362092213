<template>
  <section>
    <LoadingSpinner v-if="loading" />
    <b-container class="py-md-4">
      <b-row>
        <b-col 
          md="4" 
          class="text-center mt-5"
        >
          <b-row>
            <b-col 
              cols="12" 
              :class="[
                style['profile-pic-wrap'], 'mb-4'
              ]"
            >
              <div
                :class="[
                  style['profile-pic'],
                  'rounded-circle border border-white',
                ]"
                :style="{
                  backgroundImage: `url(${profile_image_url}), url(${API_IMAGE_URL})`,
                }"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col 
              cols="12" 
              class="text-left mt-5"
            >
              <h6 
                class="text-uppercase"
              >
                Event
              </h6>
            </b-col>
            <b-col 
              cols="12" 
              :class="['pr-5 text-left py-3', style['empty-events']]"
            >
              <p class="mb-0">
                Date: <span class="font-weight-light">{{ start_date }}</span>
              </p>
              <p class="font-weight-light font-open-sans">
                {{ day }}, {{ start_time }} - {{ end_time }} ({{ callType }})
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="8">
          <h3 class="font-weight-bold mb-md-5 mb-2 pb-2 border-bottom">
            Profile Details
          </h3>
          <b-row>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div class="font-weight-600">
                  Name
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="name"
                      v-model="name"
                      name="name"
                      type="text"
                      disabled
                      :class="[style['field'], 'bg-transparent', 'border shadow-none']"
                    />
                  </b-input-group>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div class="font-weight-600">
                  Last Name
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="surname"
                      v-model="surname"
                      name="surname"
                      type="text"
                      disabled
                      :class="[style['field'], 'bg-transparent', 'border shadow-none']"
                      data-vv-as="Last Name"
                    />
                  </b-input-group>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div class="font-weight-600">
                  Email
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="email"
                      v-model="email"
                      name="email"
                      type="email"
                      :class="[style['field'], 'bg-transparent', 'border shadow-none']"
                      disabled
                    />
                  </b-input-group>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div class="font-weight-600">
                  Age
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="age"
                      v-model="age"
                      name="age"
                      type="text"
                      :class="[style['field'], 'bg-transparent', 'border shadow-none']"
                      disabled
                    />
                  </b-input-group>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div class="font-weight-600">
                  Birthdate
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="birthdate"
                      v-model="birthdate"
                      name="birthdate"
                      type="text"
                      :class="[style['field'], 'bg-transparent', 'border shadow-none']"
                      disabled
                    />
                  </b-input-group>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div class="font-weight-600">
                  Phone number
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="number"
                      v-model="number"
                      name="number"
                      type="text"
                      :class="[style['field'], 'bg-transparent', 'border shadow-none']"
                      disabled
                    />
                  </b-input-group>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
//getGabSlotDetails
import style from "../../styles/settings.module.scss";
import LoadingSpinner from "../Common/LoadingSpinner";
import { getGabSlotDetails } from '../../api/meeting';
import moment from 'moment-timezone';
import { API_IMAGE_URL } from '../../config/config';

export default {
    components: {LoadingSpinner},
    props: {
        flag: {
            type: String,
            default: 'edit',
            require: true
        },
        profilePicUrl: {
            type: String,
            default: '',
            require: true
        }
    },
    data() {
        return {
            style,
            name: "",
            surname: "",
            email: "",
            password: "",
            profile_pic: null,
            profile_image_url: '',
            loading: false,
            age: '',
            birthdate: '',
            number:'',
            start_time: '',
            end_time: '',
            day: '',
            callType: '',
            start_date:'',
            API_IMAGE_URL
        };
    },
    async created(){
        this.loading = true;
        let gabId = window.atob(this.$route.params.gabId);
        let slot_id = window.atob(this.$route.params.username);
        let details = await getGabSlotDetails(slot_id, gabId);
        if (Object.keys(details).length > 0){
            this.loading = false;
            this.name = details.booking.meet_with.name;
            this.surname = details.booking.meet_with.surname;
            this.email = details.booking.meet_with.email;
            this.profile_image_url = details.booking.meet_with.profile_pic_url;
            this.age = details.booking.meet_with.age;
            this.birthdate = details.booking.meet_with.birthdate;
            this.number = details.booking.meet_with.phone_number;
            let start = new Date(details.start_time.replace(/-/g, '/')); //+' UTC'
            let end = new Date(details.end_time.replace(/-/g, '/')); //+' UTC'
            this.start_time = moment(start).format('h:mm a');
            this.end_time = moment(end).format('h:mm a');
            this.day = moment(start).format('dddd');
            this.start_date = moment(start).format('YYYY-MM-DD');
            this.callType = details.call_type === 1 ? 'Videocall' : 'Live stream';
        }
    },
};

</script>